import React from 'react';
import cn from 'classnames';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import Section from '@/ui/shared/components/Landing/Sections/Section';
import SectionContainer from '@/ui/shared/components/Landing/Sections/SectionContainer';
import SectionHeader from '@/ui/shared/components/Landing/Sections/SectionHeader';
import TabsSlider from '@/ui/shared/components/Landing/Sections/TabsSlider';
import { detailsTabsList } from './tabs';
import styles from './details.scss';
import baseStyles from '@/dataroom/ui/components/Landing/researchRoom/tenantResearchRoomStyles.scss';
import { IClassNames } from '@/ui/shared/components/Landing/Sections/TabsSlider/interface';

const classNames: IClassNames = {
  imgClassName: styles.img,
  dotsListClassName: styles.dotsList,
  titleClassName: styles.title,
};

const Details = () => (
  <Section padding="top">
    <SectionContainer size="large">
      <SectionHeader
        title="No one does compliance better than ResearchRoom."
        description="ResearchRoom’s deeply integrated checklist and audit workflows make checking off compliance requirements easier than ever."
        className={ styles.headerWrapper }
      />
      <TabsSlider content={ detailsTabsList } classNames={ classNames } sliderId="details-slider" />
      <div className={ styles.featuresButton }>
        <NextNavLink
          to="/features"
          data-test="exploreOurFeatures"
        >
          <Button
            variant={ variantTypes.action }
            className={ cn(baseStyles.actionButton, styles.exploreButton) }
            title="Explore More Features"
            dataTest="exploreOurFeaturesButton"
          />
        </NextNavLink>
      </div>
    </SectionContainer>

  </Section>
);

export default Details;
