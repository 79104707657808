import { useCallback } from 'react';
import cn from 'classnames';

import Image from '@/ui/shared/components/Next/Image';
import SlickSlider from '@/ui/shared/components/Landing/SlickSlider';
import styles from './tabsSlider.scss';
import { Icon } from '@dealroadshow/uikit';
import { IClassNames, ITab } from '@/ui/shared/components/Landing/Sections/TabsSlider/interface';

interface IProps {
  content: ITab[],
  classNames?: IClassNames,
  sliderId: string,
}

const imageSliderSettings = {
  dots: true,
  fade: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesPerRow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const TabsSlider = ({
  content,
  classNames = {},
  sliderId,
}: IProps) => {
  const customDots = useCallback((dots) => (
    <ul>
      { dots.map((item, index) => {
        const {
          key,
          icon,
        } = content[index] ?? {};
        return (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={ `dot${ index }` }
            data-test={ `${ key }SlideButton` }
            className={ cn(styles.dot, item.props.className) }
          >
            <div
              { ...item.props.children.props }
              className={ styles.dotButton }
            >
              <span className={ cn(styles.dotNumber, {
                [styles.iconWrapper]: icon,
              }) }
              >
                { icon ? <Icon type={ icon } /> : +item.key + 1 }
              </span>
              <div className={ styles.dotText }>
                <h4 className={ styles.title } data-test={ `${ key }SlideTitle` }>{ content[item.key].title }</h4>
                <div
                  className={ styles.description }
                  data-test={ `${ key }SlideDescription` }
                >
                  { content[item.key].description }
                </div>
              </div>
            </div>
          </li>
        );
      }) }
    </ul>
  ), [content]);

  // @ts-ignore
  return (
    <div className={ styles.slidersWrapper }>
      <SlickSlider
        key={ sliderId }
        dotsClass={ cn(styles.dotsList, classNames.dotsListClassName) }
        { ...imageSliderSettings }
        // @ts-ignore
        appendDots={ customDots }
      >
        { content.map((slide) => (
          <div key={ slide.key } data-test={ `${ slide.key }SlideContent` }>
            <Image
              className={ cn(styles.img, classNames.imgClassName) }
              src={ slide.img.src }
              width={ slide.img.width }
              height={ slide.img.height }
              placeholder="empty"
              alt="Tab image"
            />
            { slide.imgLabel && <p className={ styles.imgLabel }>{ slide.imgLabel }</p> }
            <div className={ styles.slideText }>
              <h4 className={ cn(styles.title, classNames.titleClassName) }>
                { slide.title }
              </h4>
              <div className={ styles.description }>{ slide.description }</div>
            </div>
          </div>
        )) }
      </SlickSlider>
    </div>
  );
};

export default TabsSlider;
