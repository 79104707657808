import React from 'react';
import IntroComponent, { AnimatedTitle } from '@/ui/shared/components/Landing/Sections/Intro';
import StartActions from '../StartActions';

import poster from './assets/poster.svg';
import styles from './intro.scss';

const Intro = () => (
  <IntroComponent
    className={ styles.intro }
    title={ (
      <>
        IPO research distribution made { ' ' }
        <AnimatedTitle
          sliderList={ ['accessible.', 'simple.', 'efficient.', 'compliant.', 'secure.'] }
          className={ styles.introAnimatedTitle }
        />
      </>
    ) }
    description={ (
      <p className={ styles.description }>
        ResearchRoom is a secure document e-delivery service dedicated to IPO research. It simplifies
        compliance without compromising your process and provides a seamless investor experience,
        backed by fanatical 24x7 support for all stakeholders.
      </p>
    ) }
    // @ts-ignore
    poster={ poster }
    actionButtons={ <StartActions /> }
  />
);

export default Intro;
